import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "#redux/slices/globalUISlice";
import BookCard from "./BookCard";
import ClubCard from "./ClubCard";
import QuestCard from "./QuestCard";
import ListCard from "./ListCard";
import PostCard from "./PostCard";
import ReviewCard from "./ReviewCard";
import ReadingChallengeCard from "./ReadingChallengeCard";
import BadgeCard from "./BadgeCard";
import api from "#utils/api";
import ChatBubble from "#assets/chat-bubble.svg";
import { getMaterialIcon } from "#utils/icons";
import { timeAgo } from "#utils/utils";
const FavoriteBorderOutlinedIcon = getMaterialIcon("FavoriteBorderOutlined");
const FavoriteOutlinedIcon = getMaterialIcon("FavoriteOutlined");

const FeedCard = ({ activityItemData, showReply = true, commentId }) => {
  const dispatch = useDispatch();
  const [activityItem, setActivityItem] = useState(activityItemData);
  const [loadingLike, setLoadingLike] = useState(false);
  const { currentUserId } = useSelector((state) => state.user);

  useEffect(() => {
    setActivityItem(activityItemData);
  }, [activityItemData]);

  const ACTIVITY_TYPE_COLOR_MAP = {
    book_post: "bg-mainBlue",
    club_post: "bg-pastelBlue",
    quest_post: "bg-seafoam",
    global_announcement: "bg-yellow",
    global_item: "bg-pastelPink",
    created_comment: "bg-pastelOrange",
  }; // defaults to bg-pastelPurple if not included here

  const LIKEABLE_ACTIVITY_TYPES = [
    "finished_book",
    "dnf_book",
    "tbr_book",
    "start_book",
    "paused_book",
    "completed_yearly_goal",
    "set_yearly_goal",
    "joined_quest",
    "earned_badge",
    "joined_club",
    "created_club",
  ];

  const renderCard = (resource_type, resource, commentId = null) => {
    switch (resource_type) {
      case "Post":
        return <PostCard postData={resource} variant="withImage" commentId={commentId} />;
      case "Book":
        return <BookCard book={resource} variant="large" />;
      case "Club":
        return <ClubCard club={resource} />;
      case "Challenge":
        return <QuestCard quest={resource} variant="large" />;
      case "List":
        return <ListCard listData={resource} commentId={commentId} />;
      case "Review":
        return <ReviewCard review={resource} commentId={commentId} variant="with-image" />;
      case "YearlyGoal":
        return <ReadingChallengeCard challenge={resource} variant="feed" />;
      case "ActivityItem":
        return <FeedCard activityItemData={resource} commentId={commentId} />;
      case "Comment":
        return renderCard(resource.resource_type, resource.resource, resource.id);
      case "UserBadge":
        return <BadgeCard badge={resource} />;
      default:
        return <p>Unhandled resource_type in FeedCard: {resource_type}</p>;
    }
  };

  const renderTitle = () => {
    if (
      activityItem.action_type === "book_post" ||
      activityItem.action_type === "club_post" ||
      activityItem.action_type === "quest_post"
    ) {
      return (
        <p className="font-bold overflow-hidden whitespace-nowrap text-ellipsis">
          {activityItem.title}{" "}
          <a href={activityItem.forum_link}>
            {" "}
            {/* {activityItem.action_type === "book_post" ? "book" : activityItem.forum_name} */}
            {activityItem.forum_name}
          </a>{" "}
          forum
        </p>
      );
    }

    if (activityItem.username) {
      const truncatedTitle = activityItem.title.split(" ").slice(1).join(" ");
      return (
        <p className="font-bold">
          <a href={`/users/${activityItem.username}`}>{activityItem.username}</a> {truncatedTitle}
        </p>
      );
    }

    return <p className="font-bold">{activityItem.title}</p>;
  };

  const handleLike = (e) => {
    e.stopPropagation();
    setLoadingLike(true);
    api.post(`/feed/like`, { id: activityItem.id });
    setActivityItem((prevState) => ({ ...prevState, is_liked: true, upvotes: prevState.upvotes + 1 }));
    setLoadingLike(false);
  };

  const handleRemoveLike = (e) => {
    e.stopPropagation();
    setLoadingLike(true);
    api.post(`/feed/remove_like`, { id: activityItem.id });
    setActivityItem((prevState) => ({ ...prevState, is_liked: false, upvotes: prevState.upvotes - 1 }));
    setLoadingLike(false);
  };

  return (
    <div
      className={`${
        activityItem.action_type === "created_comment" && activityItem.resource.resource_type === "ActivityItem"
          ? "bg-transparent shadow-none border-none !p-0"
          : ACTIVITY_TYPE_COLOR_MAP[activityItem.action_type] || "bg-pastelPurple"
      } w-full max-w-[900px] rounded-default border border-offBlack text-left flex flex-col p-3 md:p-4 gap-2`}
    >
      <div className="flex justify-between items-start">
        {renderTitle()}
        <div
          className={`${
            activityItem.action_type === "created_comment" && activityItem.resource.resource_type === "ActivityItem"
              ? "hidden"
              : "text-[10px] md:text-[12px] ml-1"
          }`}
        >
          {timeAgo(activityItem.created_at)}
        </div>
      </div>
      {renderCard(activityItem.resource_type, activityItem.resource)}
      {LIKEABLE_ACTIVITY_TYPES.includes(activityItem.action_type) && showReply && (
        <div
          className="flex gap-3 justify-start items-center mt-1 ml-1 font-bold cursor-pointer"
          onClick={() => {
            dispatch(
              openModal({
                name: "showActivityItem",
                data: { activityItemId: activityItem.id, setCardActivityItem: setActivityItem, commentId },
              })
            );
          }}
        >
          <div
            style={{
              boxShadow: `1.8px 1.8px 0 0 #9C9C9C`,
            }}
            className="flex gap-1 self-start items-center justify-center rounded-default border h-[26px] px-2.5 min-w-[51px] bg-transparent border-gray"
          >
            <button
              className="flex justify-start items-center"
              onClick={activityItem.is_liked ? handleRemoveLike : handleLike}
              disabled={loadingLike || activityItem.user_id === currentUserId}
            >
              {activityItem.is_liked ? (
                <FavoriteOutlinedIcon color="#FF695F" fontSize="small" className="text-red" />
              ) : (
                <FavoriteBorderOutlinedIcon
                  color="#D2D2D2"
                  fontSize="small"
                  className={`text-gray ${activityItem.user_id !== currentUserId && "hover:text-red"}`}
                />
              )}
            </button>
            <div className="text-xs text-center text-gray">{activityItem.upvotes}</div>
          </div>
          <div
            style={{
              boxShadow: `1.8px 1.8px 0 0 #9C9C9C`,
            }}
            className="items-center text-xs text-gray rounded-default border py-1 px-3 bg-transparent border-gray"
          >
            <img src={ChatBubble} className="inline" />
            {"  "}
            {activityItem.comment_count}
          </div>
          <div className="text-gray text-xs">Reply</div>
        </div>
      )}
    </div>
  );
};

export default FeedCard;
